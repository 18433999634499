import { InMemoryCache } from "@apollo/client";
import possibleTypes from "./../../public/data/possibleTypes.json";

const inMemoryCache = new InMemoryCache({
  possibleTypes,
  /**
   * The limit of the number of result objects that will be retained in memory to speed up repeated reads to the cache.
   */
  resultCacheMaxSize: Math.pow(2, 16),
  /**
   * typePolicies are used to define how the cache should handle the data for each type.
   * This is needed, if Apollo Client doesn't correctly merge the data of different queries
   * for the same type and dataset (e.g. teaser query and detail page query). Incorrect
   * auto-merging results in overwriting cached data again and again.
   * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/
   * @see https://www.apollographql.com/docs/react/pagination/core-api/
   */
  typePolicies: {
    // Apollo cache doesn't correctly merge Views by default.
    // This custom merge function merges new "execute" objects into the cache (one for each set of variables).
    View: {
      fields: {
        executable: {
          merge(existing = {}, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodeProject images by default.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodeProject: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodePerson images by default.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodePerson: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodeNews images by default.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodeNews: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodeNews images by default.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodeEvent: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
  },
});

export default inMemoryCache;
